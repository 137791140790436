import React, { useState } from 'react';
import './Book.css';

const Book = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const pages = [
    '"Page 1: Meow meowwwww, cholly this isn\'t the only thing I want to give you but it\'s the most I could do right now,\n \n but this will be our diary, i\'ll describe everything of my feeling from very start. Of how I got my feelings for you. Eppud neek padipoya , everything basically. So MODELEDTAMAAAAA?! I\'ll mix 9th , 10th and btech moments all so jiggle jiggle mind you\'ll have \n Turn the page my little kulfiiiiiiii❤️❤️❤️❤️❤️',



    'Chapter 1 Prema Katha chitram (IDK if it\'s a sad movie T_T but noice title ani kept me) \n First ninnu 9th lo chusinapudu ofc 8th lo chudale ani kaadu , 8th idk antha love la feel raale coz ekkirinchukone vaalam and we were like kids. but later 9th lo nee bday ki chusinapudu 2nd september, idk what arouse but liking la oo feel ala ochesindi idk how to explain that in words it was totally random. But after that neek gurtunde untadi we wrote social exam idk practice exam oo edo antaru mari , proudly I failed it ofc apudu ala anthe le, but nuv nannu nee pen work chestuna naa pen adigav , i kinda felt u also like me, appatiki although im not sure how you felt about me , Ala digipoya anthe. Inka mothaniki that was a memorable event , aa exam lo me peeking in ur paper , since ur a smol good kitten u no show me wahh, haha sarle, then later 7th came.Wait i\'ll write in next page this wont look readable. ',

    'It did take me a lot of while to search for a good keychain , coz i never gave a gift to any girl and im also not sure if you\'d like it, but trusted myself and gave u that keychain. oh shit in between i forgot i proposed u, near the scholastic general ga ette place degara, near the music room but inka front near the exit, I called u asked if u had a boyfriend u said no, I said i love you. U had a shocking face, I felt happy for first idk why but later overthinked a lot naak evad padtar ani, I didnt hope much coz uk appatike I wasnt that of a good student and avg in sports, inka discouraged myself.But gathered courage elaina and asked yes aa no aa ani, and u said meow ur dad no like ani me also said nothing T_T inka ala ala modalainadi mana katha',

    'Chapter 2: BTECH (ENGINEERING IS ASS)\n kihihihi funny title sarle not that gaani, Just deployed coz the time isnt enough, so I was at a phase where for sure love oddu lawda oddu naak evar padaru , i play game eat sleep antu, aa time lo got a req from a very special person with the id "srichinnu_793 bday matching, nickname matching lopala Daroori Sribrinda, the one who never gives space between Sri and brinda nundi req came. Inka aagadu had to accept, i thought for sure ee paatiki u forgot about me ane ankunna time ki u called, discord lo game lo maatlade odini voice nundi video call chesav, ofc i need to look good so manchi t shirt eskuna, hehehe u pogududings me , after call i went to bathroom and siggu padda (shh its a secret). Inka nuve when u asked that "Nen neek inko chance iddam ankuntuna, utilize cheskuntava ani" my gunde ninda gudi gantale , 0 hesitation, brain went off but said off OFCCCCC , adi avvagane oorantha pracharam chesa literally, ammayalatho contacts fasak , naak dorakadu, ledu ankuna love life kanna ekkuva dorikesesindiiiiii',
    ''
  ];

  const nextPage = () => {
    if (currentPage < pages.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="book">
      <div className="page" onClick={nextPage}>
        <p>{pages[currentPage]}</p>
      </div>
      <div className="navigation">
        {currentPage > 0 && (
          <button className="prev-btn" onClick={prevPage}>
            &#9664; Previous
          </button>
        )}
        {currentPage < pages.length - 1 && (
          <button className="next-btn" onClick={nextPage}>
            Next &#9654;
          </button>
        )}
      </div>
    </div>
  );
};

export default Book;
